import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { concat, merge, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DropdownItem } from '../../../../../shared/models/common.model';
import { User } from '../../../../../shared/models/user.models';
import { DropdownService } from '../../../../../shared/service/dropdown.service';
import { CertificateStatus } from '../../../../certificate/shared/certificate.model';
import { Member } from '../../../../loa/shared/loa.model';
import { ApproverDropdown } from '../../../model/memo.model';
import { DdocLawSectionObj } from '../../upload-memos/loa-customizer/signing-law-section-radio/signing-law-section-radio.component';
@Component({
  selector: 'app-approval-dropdown',
  templateUrl: './approval-dropdown.component.html',
  styleUrls: ['./approval-dropdown.component.scss'],
})
export class ApprovalDropdownComponent implements OnChanges, OnInit {
  certificateStatus: CertificateStatus = null;
  people$: Observable<ApproverDropdown[]>;
  peopleInput$ = new Subject<string>();
  peopleLoading = false;

  @Input() clearable = false;
  @Input() selectedApprovals: number[] = [];
  @Input() approval: Partial<Member>;
  @Input() disabled = false;
  @Input() disabledApprover = false;
  @Input() freeLawSelection = false;
  @Input() enableAttachSignature = false;
  @Input() ddocEnable = false;
  @Input() ddocLawSection: DdocLawSectionObj;
  @Input() allowLOAChange = true;
  @Output() approvalChange = new EventEmitter();
  @Output() ddocLawSectionChange =
    new EventEmitter<DdocLawSectionObj>();
  @Input() isContract = false;
  @Input() jobPositionSelectedList = [];
  @Input() isTypeUserOfApprover;

  constructor(private dropdownService: DropdownService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ddocEnable) {
      if (!changes.ddocEnable.currentValue) {
        this.ddocLawSection = { lawSection: null };
      }
    }
    if ('isTypeUserOfApprover' in changes) {
      const isTypeUserOfApproverChange = changes.isTypeUserOfApprover;
      if (
        isTypeUserOfApproverChange.currentValue === 'job_position'
      ) {
        this.setAllPeople();
      }
    }

    if ('jobPositionSelectedList' in changes) {
      this.setAllPeople();
    }

    if (changes.disabled && changes.disabled.currentValue) {
      this.disabled = false;
    }
  }

  ngOnInit(): void {
    if (this.jobPositionSelectedList.length > 0) {
      this.setAllPeople();
    } else {
      this.setPeople();
    }
  }

  setAllPeople(): void {
    const initialFetch$ = this.dropdownService
      .getDropdown({
        type: 'people',
        all: 'true',
        group: 'General,Contract Management',
        with_signature: this.enableAttachSignature,
        job_positions: this.jobPositionSelectedList.toString() || '',
        query: '',
      })
      .pipe(
        map(({ people }) =>
          people.map(({ value, label, context }) => ({
            person: value,
            full_name: label,
            context,
            ...(this.enableAttachSignature && {
              signature: context.signature,
            }),
          })),
        ),
        catchError(() => of([] as ApproverDropdown[])),
      );

    const inputFetch$ = this.peopleInput$.pipe(
      distinctUntilChanged(),
      filter((term) => term != null && term !== ''),
      debounceTime(300),
      switchMap((query) =>
        this.dropdownService
          .getDropdown({
            type: 'people',
            all: 'true',
            group: 'General,Contract Management',
            with_signature: this.enableAttachSignature,
            job_positions:
              this.jobPositionSelectedList.toString() || '',
            query,
          })
          .pipe(
            map(({ people }) =>
              people.map(({ value, label, context }) => ({
                person: value,
                full_name: label,
                context,
                ...(this.enableAttachSignature && {
                  signature: context.signature,
                }),
              })),
            ),
            map((people) =>
              people.filter(
                ({ person }) =>
                  !this.selectedApprovals.includes(person),
              ),
            ),
            catchError(() => of([] as ApproverDropdown[])),
            tap(() => (this.peopleLoading = false)),
          ),
      ),
    );

    const combinedFetch$ = merge(initialFetch$, inputFetch$);

    this.people$ = combinedFetch$.pipe(
      tap(() => (this.peopleLoading = false)),
    );
  }

  setPeople(): void {
    this.people$ = concat(
      of([] as ApproverDropdown[]),
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(300),
        tap(() => (this.peopleLoading = true)),
        switchMap((query) => {
          const params: { [k: string]: any } = {
            type: 'people',
            all: 'true',
            group: 'General,Contract Management',
            with_signature: true,
            query,
          };
          if (this.enableAttachSignature) {
            params.with_signature = true;
          }
          return this.dropdownService.getDropdown(params).pipe(
            map((obj) =>
              obj.people.map(
                (
                  people: DropdownItem<number, PeopleDropdownContext>,
                ) => {
                  const approver: ApproverDropdown = {
                    person: people.value,
                    full_name: people.label,
                    context: people.context,
                  };
                  if (this.enableAttachSignature) {
                    approver.signature = people.context.signature;
                  }
                  return approver;
                },
              ),
            ),
            map((people) => {
              return people.filter(
                (person) =>
                  !this.selectedApprovals.includes(person.person),
              );
            }),
          );
        }),
        catchError(() => of([] as ApproverDropdown[])),
        tap(() => (this.peopleLoading = false)),
      ),
    );
  }

  onDropdownChange(dropdown: ApproverDropdown): void {
    this.approvalChange.emit(dropdown);
  }
}

interface PeopleDropdownContext extends Partial<User> {
  signature: string;
}
